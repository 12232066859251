/* eslint-disable max-len */
import { AppLayout, Base404 } from '@technologyadvice/relay-frontend'
import Brands from '@views/pages/Brands/Brands.vue'
import Home from '@views/pages/Home.vue'
import JoinAccount from '@views/JoinAccount.vue'
import ForgotPassword from '@views/ForgotPassword.vue'
import Help from '@views/pages/Help.vue'
import Login from '@views/Login.vue'
import LoginV2 from '@views/LoginV2.vue'
import EmailValidation from '@views/Register/EmailValidation.vue'
import ResetPassword from '@views/ResetPassword.vue'
import UserInvite from '@views/UserInvite.vue'
import store from '@store/auth'
import Users from '@views/pages/Users/Users.vue'
import ViewBrand from '@views/pages/Brands/ViewBrand.vue'
import ViewUser from '@views/pages/Users/ViewUser.vue'
import ResetUserPassword from '@views/pages/Users/ResetUserPassword.vue'

import Plans from '@views/pages/PlanManagement/Plans.vue'
import Attributes from '@views/pages/PlanManagement/Attributes.vue'
import EditPlan from '@views/pages/PlanManagement/EditPlan.vue'
import EditAttribute from '@views/pages/PlanManagement/EditAttribute.vue'

import IntegrationHub from '@views/pages/Integrations/IntegrationHub.vue'
import IntegrationDetails from '@views/pages/Integrations/IntegrationDetails.vue'
import About from '@views/pages/Integrations/ZoomInfo/About.vue'
import Installation from '@views/pages/Integrations/ZoomInfo/Installation.vue'
import Settings from '@views/pages/Integrations/ZoomInfo/Settings.vue'

import api from '@api/auth'

const routes = [
    {
        name: 'loginV2',
        path: '/login',
        component: LoginV2,
        meta: { title: 'Login' },
    },
    {
        name: 'login',
        path: '/login/v1',
        component: Login,
        meta: { title: 'Login' },
    },
    {
        name: 'ForgotPassword',
        path: '/password/reset',
        component: ForgotPassword,
        meta: { title: 'Forgot Password' },
    },
    {
        name: 'ResetPassword',
        path: '/password/reset/:token',
        component: ResetPassword,
        props: true,
        alias: '/password/reset/:token/setup',
    },
    {
        path: '/register/verify/:token',
        component: () => import(/* webpackChunkName: "main" */ '@views/Register/Wrapper.vue'),
        children: [
            {
                name: 'Email Verification',
                path: '',
                component: EmailValidation,
                meta: { title: 'Register: Email Validation' },
                props: true,
            },
        ],
    },
    {
        name: 'UserInvite',
        path: '/invite/:token',
        component: UserInvite,
        alias: '/invite',
        props: true,
    },
    {
        name: 'JoinAccount',
        component: JoinAccount,
        path: '/join-account/:account_uuid',
        meta: {
            title: 'Join Account',
            requiresAuth: true,
        },
    },
    {
        path: '/',
        component: AppLayout,
        meta: { requiresAuth: true },
        props: {
            path: 'accounts',
            authApi: api,
        },
        children: [
            {
                name: 'Home',
                component: Home,
                path: '/',
                meta: {
                    title: 'Traction | Home',
                },
            },
            {
                name: 'UserList',
                path: '/users',
                component: Users,
                meta: {
                    title: 'Users',
                },
                // only allow this route for users with manage-users scope
                beforeEnter: (to, from, next) => {
                    const currentUser = store.state.user

                    if (currentUser.scopes.includes('manage-users')) next()
                    else next(`/users/${currentUser.uuid}`)
                },
            },
            {
                path: '/users/me',
                beforeEnter: (to, from, next) => {
                    next({ name: 'Profile', params: { user_uuid: store.state.user.uuid } })
                },
            },
            {
                name: 'Profile',
                path: '/users/:user_uuid',
                component: ViewUser,
                props: true,
                meta: {
                    title: 'Profile',
                },
                // only allow this route for users with manage-users scope or if user's uuid matches requested id
                beforeEnter: (to, from, next) => {
                    const requestedUserUuid = to.params.user_uuid
                    const currentUser = store.state.user
                    const currentUserUuid = currentUser.uuid

                    if (currentUser.scopes.includes('manage-users') || requestedUserUuid === currentUserUuid) next()
                    else next(`/users/${currentUserUuid}`)
                },
            },
            {
                name: 'ResetUserPassword',
                path: '/users/:userUuid/reset-password',
                component: ResetUserPassword,
                props: true,
                meta: {
                    title: 'Reset User Password',
                },
                // only allow this route for the user requested
                beforeEnter: (to, from, next) => {
                    const requestedUserUuid = to.params.userUuid
                    const currentUserUuid = store.state.user.uuid

                    if (requestedUserUuid === currentUserUuid) next()
                    else next('/')
                },
            },
            {
                name: 'BrandList',
                path: '/brands',
                component: Brands,
                meta: {
                    title: 'Brands',
                },
                // only allow this route for users with manage-users scope
                beforeEnter: (to, from, next) => {
                    const currentUser = store.state.user

                    if (currentUser.scopes.includes('manage-brands')) next()
                    else next(`/users/${currentUser.uuid}`)
                },
            },
            {
                name: 'Brand',
                path: '/brands/:uuid',
                component: ViewBrand,
                props: true,
                meta: {
                    title: 'Brand',
                },
                // only allow this route for users with manage-users scope or if user's uuid matches requested id
                beforeEnter: (to, from, next) => {
                    const currentUser = store.state.user

                    if (currentUser.scopes.includes('manage-brands')) next()
                    else next(`/users/${currentUser.uuid}`)
                },
            },
            {
                name: 'PlanManagement',
                path: '/plan-management',
                meta: {
                    title: 'Plans',
                },
                redirect: { name: 'Plans' },
                component: () => import(/* webpackChunkName: "main" */ '@views/pages/PlanManagement/Wrapper.vue'),
                children: [
                    {
                        name: 'Plans',
                        path: 'plans',
                        meta: {
                            title: 'Plan Management: Plans',
                        },
                        component: Plans,
                    },
                    {
                        name: 'Attributes',
                        path: 'attributes',
                        meta: {
                            title: 'Plan Management: Features',
                        },
                        component: Attributes,
                    },
                ],
            },
            {
                name: 'PlanManagementEditPlan',
                path: '/plan-management/plans/:uuid',
                props: true,
                meta: {
                    title: 'Plan Management: Edit Plan',
                },
                component: EditPlan,
            },
            {
                name: 'PlanManagementEditAttribute',
                path: '/plan-management/attributes/:uuid',
                props: true,
                meta: {
                    title: 'Plan Management: Edit Feature',
                },
                component: EditAttribute,
            },
            {
                name: 'Help',
                path: '/help',
                component: Help,
                meta: {
                    title: 'Help',
                },
            },
            {
                name: 'FeatureManagement',
                path: '/feature-management',
                component: () => import(/* webpackChunkName: "main" */ '@views/pages/Features/Management.vue'),
                // only allow this route for users with can-manage-feature-flag scope
                beforeEnter: (to, from, next) => {
                    const currentUser = store.state.user

                    if (currentUser.scopes.includes('can-manage-feature-flags')) next()
                    else next('/')
                },
                meta: {
                    title: 'Feature Management',
                },
            },
            {
                name: 'ViewFeature',
                path: '/feature-management/:uuid',
                component: () => import(/* webpackChunkName: "main" */ '@views/pages/Features/ViewFeature.vue'),
                props: true,
                meta: {
                    title: 'Feature Flag',
                },
                beforeEnter: (to, from, next) => {
                    const currentUser = store.state.user

                    if (currentUser.scopes.includes('can-manage-feature-flags')) next()
                    else next(`/users/${currentUser.uuid}`)
                },
            },
            {
                name: 'IntegrationHub',
                path: '/integrations',
                component: IntegrationHub,
                meta: {
                    title: 'Integration Hub',
                },
                beforeEnter: (to, from, next) => {
                    const currentUser = store.state.user

                    if (currentUser.features.includes('integration-hub')) next()
                    else next(`/users/${currentUser.uuid}`)
                },
            },
            {
                name: 'IntegrationDetails',
                path: '/integrations/:type',
                component: IntegrationDetails,
                meta: {
                    title: 'Integration Details',
                },
                redirect: { name: 'About' },
                children: [
                    {
                        name: 'About',
                        path: 'about',
                        component: About,
                    },
                    {
                        name: 'Installation',
                        path: 'installation',
                        meta: {
                            title: 'Integration Details: Installation',
                        },
                        component: Installation,
                    },
                    {
                        name: 'Settings',
                        path: 'settings',
                        meta: {
                            title: 'Integration Details: Settings',
                        },
                        component: Settings,
                    },
                ],
            },
            {
                path: '/404',
                alias: '*',
                name: '404',
                component: Base404,
                meta: {
                    title: '404: Page Not Found',
                },
            },
        ],
    },
]

export default routes
